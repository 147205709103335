import React from "react";
import DefaultLayout from "../../components/DefaultLayout";
import Hero from "../../components/bricks/Hero";
import FeatureOverview from "../../components/FeatureOverview";
import ThemedSingleFeature from "../../components/bricks/ThemedSingleFeature";
import {themeBright, themeDark} from "../../page-styles/landingpage.css";
import ThemeBox from "../../components/bricks/ThemeBox";

const Features = ({location}) => (
  <DefaultLayout
    title="Search & Order"
    description="Use our advanced search and ordering options to dice and slice your work just the way you need."
    location={location}
  >
    <Hero size="sm" title="Search & Order" />
    <ThemeBox theme={themeBright} withBorder>
      <ThemedSingleFeature childAsDescription img="search-1.png">
        Searching on Codecks is an extremely useful functionality that you will want to use often.
        Our interactive search widget allows you to combine even complex search queries.
      </ThemedSingleFeature>

      <ThemedSingleFeature
        childAsDescription
        title="Save common searches"
        img="search-2.png"
        imgRight
      >
        If you often conduct the same searches, you can save your queries for easy retrieval. By
        default, Codecks has the <b>my cards</b> saved search so that you can easily retrieve all
        cards you own.
      </ThemedSingleFeature>

      <ThemedSingleFeature
        childAsDescription
        title="No need to switch contexts when searching"
        img="search-3.png"
      >
        If you hit enter on a search result, Codecks will apply that as a filter (green pill) on
        your current context (e.g. your entire project, or your currently opened deck). This is a
        great way to narrow down your view on a temporary basis, and once you’re done, you can
        dismiss the pill to return to an unfiltered view.
      </ThemedSingleFeature>
    </ThemeBox>

    <ThemeBox theme={themeDark} withBorder title="Group cards in swim lanes">
      <ThemedSingleFeature childAsDescription dark img="order-1.png">
        Whenever you see a group of cards you'll be able to order them by various kinds of
        categories. This allows you to model a kanban work flow, show cards of the current sprint or
        separate high priority cards from the rest.
      </ThemedSingleFeature>

      <ThemedSingleFeature
        childAsDescription
        dark
        title="Works great in combination with search"
        img="order-2.png"
        imgRight
      >
        Want to see all your cards and group them by milestone? Find all high prio cards and see who
        owns them? Combining search and ordering allows you to see your project from a big variety
        of perspectives.
      </ThemedSingleFeature>

      <ThemedSingleFeature childAsDescription dark title="Drag and drop support" img="order-3.png">
        Most swimlanes can be used for drag and drop interactions to allow to quickly change
        priority, owner, milestone and much more. Multi-select support allows you to drag multiple
        cards as well.
      </ThemedSingleFeature>
    </ThemeBox>
    <FeatureOverview />
  </DefaultLayout>
);

export default Features;
